'use strict';

const PayPalBaseModel = require('./payPalBaseModel');
const helper = require('../../helper');
const payPalCheckoutModelHelper = require('../helpers/payPalCheckoutModelHelper');
const billingFormHelper = require('../helpers/payPalBillingFormHelperGlobal');
const paypalListHelper = require('../helpers/payPalListHelper');

/**
 * PayPal checkout model constructor
 * @param {Object} alertHandlerModel alert handler model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations configs from server (taken from data attr)
 * @param {Object} $continueButton continue button js container
 * @param {Object} $loaderContainer loader js container
 */
function PayPalCheckoutPaymentModel(alertHandlerModel,
                                    btClientInstancePromise,
                                    payPalButtonSelector,
                                    payPalConfigurations,
                                    $continueButton,
                                    $loaderContainer) {
    PayPalBaseModel.call(this,
        alertHandlerModel,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        $loaderContainer);

    this.$continueButton = $continueButton;
    this.changePMButtonEnabled = payPalConfigurations.changePMButtonEnabled;
}

// ES5 inheritance
PayPalCheckoutPaymentModel.prototype = Object.create(PayPalBaseModel.prototype);

// For more details see same method from "PayPalBaseModel"
// Add additional configs which are needed for "Change Payment Method Button" feature
PayPalCheckoutPaymentModel.prototype.payPalCheckoutInstanceConfigs = function(btClientInstance) {
    const checkoutInstanceConfigs = PayPalBaseModel.prototype.payPalCheckoutInstanceConfigs.call(this, btClientInstance);

    if (this.changePMButtonEnabled) {
        checkoutInstanceConfigs.autoSetDataUserIdToken = true;
    }

    return checkoutInstanceConfigs;
};

// For more details see same method from "PayPalBaseModel"
// Show error in case if current basket has zero total price
PayPalCheckoutPaymentModel.prototype.onPayPalButtonClickCallback = async function(_, actions) {
    await PayPalBaseModel.prototype.onPayPalButtonClickCallback.call(this, _, actions);
    let action;

    if (this.basketData.amount === 0) {
        this.alertHandlerModel.showZeroAmountError();

        action = actions.reject();
    }

    return action;
};

// For more details see same method from "PayPalBaseModel"
// Mark new account as the session one, fill billing form with data from paypal
PayPalCheckoutPaymentModel.prototype.onApprovePaymentCallback = function(payload) {
    // Calling parrent method to proceed with PayPal payload validation
    PayPalBaseModel.prototype.onApprovePaymentCallback.call(this, payload);

    // Error will be handled on BaseModel level
    if (this.error) {
        return;
    }

    this.loader.show();

    const PayPalCheckoutPaymentModelInstance = this;
    const isBuyerAuthenificated = PayPalCheckoutPaymentModelInstance.payPalConfigurations.isBuyerAuthenticated;
    const btDetails = payload.details;
    const nonce = payload.nonce;
    const email = payload.details.email;
    const btBillingAddress = btDetails ? btDetails.billingAddress : null;

    // Remove all other Payments session payments
    helper.removeActiveSessionPayment();

    // Hide PayPal buttons block
    payPalCheckoutModelHelper.hidePayPalButtons();

    // Fill just PayPal related form fields
    billingFormHelper.fillPayPalPaymentFields(btDetails);

    // Make current PayPal account as a session account
    billingFormHelper.storePaymentAsSessionPayment(nonce, email);

    billingFormHelper.setSessionAccountOptionDefault();

    // Add billing address on session payment option level
    payPalCheckoutModelHelper.addBillingAddressOnPaymentOptionLevel(btDetails);

    // Check whether buyer has proceeded with the same email which is already stored
    // if "yes" we hide stored account with the same email in dropdown
    // if "no" we show all existent PP accounts in dropdown (stored + new session account)
    paypalListHelper.handleStoredAccountsShowing(isBuyerAuthenificated);

    //update SOM_IsExpressPayment flag at basket level via ajax.
    helper.updateIsExpressPaymentFlag (false);

    // Gennerate and add fraud data in to the billing form
    PayPalCheckoutPaymentModelInstance.fraudDataPromise
        .then(function(data) {
            // We have "fraudDataCollectionDisabled" flag only in case if we have fraud data collection disabled
            if (!data.fraudDataCollectionDisabled) {
                billingFormHelper.fillFraudDataInput(data);
            }
        })
        .then(function() {
            // Submit payment to server
            PayPalCheckoutPaymentModelInstance.$continueButton.click();

            PayPalCheckoutPaymentModelInstance.loader.hide();
        });
};

module.exports = PayPalCheckoutPaymentModel;
