'use strict';

const PayPalBaseModel = require('./payPalBaseModel');
const payPalExpressModelHelper = require('../helpers/payPalExpressModelHelper');
const billingFormHelper = require('../helpers/payPalBillingFormHelperGlobal');
const requestHelper = require('../helpers/payPalRequestsHelperGlobal');
const productHelper = require('../helpers/payPalProductPageModelHelper');
const helper = require('../../helper');

/**
 * PayPal express model constructor
 * @param {Object} alertHandlerModel alert handler model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations configs from server (taken from data attr)
 * @param {Object} $csrfToken CSRF token js container
 * @param {Object} $loaderContainer loader js container
 */
function PayPalExpressModel(alertHandlerModel,
                            btClientInstancePromise,
                            payPalButtonSelector,
                            payPalConfigurations,
                            $csrfToken,
                            $loaderContainer) {
    // Init PayPalBaseModel constructor
    PayPalBaseModel.call(this,
        alertHandlerModel,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        $loaderContainer);

    this.$csrfToken = $csrfToken;
    this.changePMButtonEnabled = payPalConfigurations.changePMButtonEnabled;
}

// ES5 inheritance
PayPalExpressModel.prototype = Object.create(PayPalBaseModel.prototype);

// For more details see same method from "PayPalBaseModel"
// Add additional configs which are needed for "Change payment method button" feature
PayPalExpressModel.prototype.payPalCheckoutInstanceConfigs = function(btClientInstance) {
    const checkoutInstanceConfigs = PayPalBaseModel.prototype.payPalCheckoutInstanceConfigs.call(this, btClientInstance);

    if (this.changePMButtonEnabled) {
        checkoutInstanceConfigs.autoSetDataUserIdToken = true;
    }

    return checkoutInstanceConfigs;
};

/**
 * Method triggers when buyer clicked on PayPal button and PayPal modal is opening
 * @returns {Object} Amount and Shipping address from basket
 */
PayPalExpressModel.prototype.onOrderCreateCallback = async function() {
    const basketDataUrl = this.basketDataUrl;

    this.basketData = await requestHelper.getBasketData(basketDataUrl);

    const isUseSavedPaypalAddress = this.changePMButtonEnabled && !this.basketData.shippingAddress;

    if (isUseSavedPaypalAddress) {
        this.savedPaypalAddress = payPalExpressModelHelper.getSavedPaypalAddress(this.payPalButtonSelector);
    }

    return PayPalBaseModel.prototype.onOrderCreateCallback.call(this);
};

// For more details see same method from "PayPalBaseModel"
// Create and submit billing data to the server. Redirect on order reivew checkout step
PayPalExpressModel.prototype.onApprovePaymentCallback = function(payload) {
    // Calling parrent method to proceed with PayPal payload validation
    PayPalBaseModel.prototype.onApprovePaymentCallback.call(this, payload, true);

    // Error will be handled on BaseModel level
    if (this.error) {
        return;
    }

    this.loader.show();

    const PayPalExpressInstance = this;
    const payloadDetails = payload.details;
    const $payPalButtonWrapper = document.querySelector('.js-braintree-cart-paypal-buttons-wrap');
    const $braintreePayPalFundingSource = document.getElementById('braintreePaypalFundingSource');
    const braintreePayPalFundingSource = $braintreePayPalFundingSource.value;

    const paypalCheckoutFormFields = helper.tryParseJSON($payPalButtonWrapper.getAttribute('data-paypal-checkout-form-fields'));
    const billingAddressData = billingFormHelper.createBillingAddressData(payloadDetails);

    // Create form data only with billing address
    const billingFormData = billingFormHelper.createBillingAddressFormData(paypalCheckoutFormFields, billingAddressData);

    billingFormHelper.appendCsrfTokenToFormData(billingFormData, PayPalExpressInstance.$csrfToken);
    billingFormHelper.appendBraintreePaypalFundingSourceToFormData(billingFormData, braintreePayPalFundingSource);

    // REGEX for js-braintree-(pdp|pvp)-button, js-braintree-(pdp|pvp)-button-global
    const BRAINTREE_BUTTON_REGEX = /(^|\s)js-braintree-p[dv]p-button(-global)?(\s|$)/;

    //update SOM_IsExpressPayment flag at basket level via ajax.
    helper.updateIsExpressPaymentFlag (true);

    // It will generate FFraud data only if it Fraud Data collection was enabled
    PayPalExpressInstance.fraudDataPromise
        .then(function(data) {
            if (!data.fraudDataCollectionDisabled) {
                billingFormHelper.appendFraudDataToFormData(billingFormData, data.deviceData);
            }
        })
        .then(function() {
            return requestHelper.validateAddresses(PayPalExpressInstance.validateAddressesUrl,
                payloadDetails);
        })
        .then(function(validationResult) {
            if (validationResult.error) {
                const parentWrapper = $payPalButtonWrapper.parentElement.parentElement;

                if (BRAINTREE_BUTTON_REGEX.test(parentWrapper.className)) {
                    productHelper.removeProductFromCart();
                }

                throw new Error(validationResult.message);
            }

            let shippingAddressData;

            // If shipping address was presented in PayPal payload, create a shipping address object,
            // stringify it and store in FormData as an input
            if (payloadDetails.shippingAddress) {
                shippingAddressData = payPalExpressModelHelper.createShippingAddressData(payloadDetails);

                payPalExpressModelHelper.appendShippingDataAsStringToFormData(billingFormData, shippingAddressData);
            }

            // Create a shipping address object, stringify it and store in FormData as an input
            billingFormHelper.appendBillingAddressAsStringToFormData(billingFormData, billingAddressData);

            billingFormHelper.appendEmailAsStringToFormData(billingFormData, payloadDetails.email);

            billingFormHelper.appendBtPaymentFieldsToFormData(billingFormData, payload);
        })
        .then(function() {
            // Submit customer form with email (CheckoutServices-SubmitCustomer)
            // as we skip step "login via guest/registered user" while express checkout
            // email is set only in case of guest checkout and if email is not already set
            payPalExpressModelHelper.submitCustomerForm(payloadDetails.email);
        })
        .then(function() {
            requestHelper.submitPaymentDataToServerPromise(PayPalExpressInstance.submitPaymentUrl,
                billingFormData,
                PayPalExpressInstance.placeOrderStageUrl,
                PayPalExpressInstance.loader,
                PayPalExpressInstance.alertHandlerModel);

            PayPalExpressInstance.loader.hide();
        })
        .catch(function(error) {
            PayPalExpressInstance.alertHandlerModel.showError(error);

            PayPalExpressInstance.loader.hide();
        });
};

module.exports = PayPalExpressModel;
